import React, { Component } from 'react'
import { AbiItem } from 'web3-utils'
import Web3 from 'web3'
import './App.css'
import { LotteryTickets, LotteryTicketsProps } from './LotteryTickets'
import { LotteryEntryForm, LotteryEntryFormProps } from "./LotteryEntryForm";
import { GASLIMIT_PER_TICKET,GET_CONTRACT_ADDRESS, CONTRACT_ABI, NETWORK, GET_NETWORK_DISPLAY_NAME, GET_NETWORK_CHAIN_ID, GET_CURRENCY_NAME, NAME, DAYOFWEEKFORDRAWING, nextDay } from "./config"
import { BahndrAppContext, FormState, PreviousLotteryWinner } from './context/BahndrAppContext'
import MetaMaskOnboarding from '@metamask/onboarding';
import detectEthereumProvider from '@metamask/detect-provider'
import { EthereumProviderError } from 'eth-rpc-errors'
import { GetAddressOfLastLotteryWinner, GetETHtoUSDExchangeRate, GET_ETHERSCAN_URL_FOR_TRANSACTION } from './external/Etherscan'
import { LotteryManager } from './LotteryManager'
export interface LotteryProps {

}
export interface LotteryState {
    tokenBalance: any,
    accounts: string[],
    canUseApp: boolean | null,
    hasMetaMaskError: boolean | null,
    metaMaskError: string
}
export class Lottery extends Component<LotteryProps, LotteryState>
{
    static contextType = BahndrAppContext
    private onboarding: MetaMaskOnboarding

    constructor(props: any) {
        super(props)
        this.state = {
            accounts: [],
            tokenBalance: 0,
            canUseApp: null,
            hasMetaMaskError: null,
            metaMaskError: ''
        }
        this.onBuyNewLotteryTicket = this.onBuyNewLotteryTicket.bind(this)

        this.onboarding = new MetaMaskOnboarding()
    }

    componentDidMount() {
        this.loadLotteryInformationFromBlockchain()
    }


    onInstallMetamaskClick = () => {
        if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
            this.onboarding.startOnboarding();
        }
    }

    getCurrentCostPerTicketInETH = (): string => {
        return Web3.utils.fromWei(this.context.costPerTicket.toString())
    }

    getCurrentCostPerTicketInUSD = (): string => {
        const costInETH = parseFloat(this.getCurrentCostPerTicketInETH());
        return (costInETH * this.context.exchangeRate).toFixed(2).toString()
    }



    getNextDrawingDate = (): string => {
        return nextDay(new Date(), DAYOFWEEKFORDRAWING).toLocaleDateString()
    }

    getCurrentPrizeInETH = (): string => {
        return this.context.prize

    }

    getCurrentPrizeInUSD = (): string => {
        const costInETH = parseFloat(this.getCurrentPrizeInETH())
        return (costInETH * this.context.exchangeRate).toFixed(2).toString()
    }

    clearErrors = () => {
        this.setState({ hasMetaMaskError: false, metaMaskError: '' })
    }

    loadLastLotteryWinnerFromBlockChain = async () => {
        const lastLotteryWinner = await GetAddressOfLastLotteryWinner(GET_CONTRACT_ADDRESS())
        if (lastLotteryWinner) {
            this.context.setPreviousLotteryWinner(lastLotteryWinner)
        }
    }

    loadLotteryInformationFromBlockchain = async () => {
        const provider: any = await detectEthereumProvider()
        const web3 = new Web3(provider)
        const balance = await web3.eth.getBalance(GET_CONTRACT_ADDRESS())
        console.log(`Current balance of contract at ${GET_CONTRACT_ADDRESS()} is ${balance} wei`)
        this.context.setPrize(web3.utils.fromWei(balance, "ether"))

        //get the current eth to usd exchange rate
        const exchangeRate = await GetETHtoUSDExchangeRate()
        console.log(`Current USD-to-ETH Exchange Rate is ${exchangeRate}`)
        this.context.setExchangeRate(exchangeRate)

        //load the last winner of the lottery from the blockchain
        await this.loadLastLotteryWinnerFromBlockChain()

        //calculate the next drawing date
        this.context.setDrawingDate(this.getNextDrawingDate())
        console.log(`Next Drawing Date: ${this.getNextDrawingDate()}}`)

        const bahndrToken = new web3.eth.Contract(CONTRACT_ABI as AbiItem[], GET_CONTRACT_ADDRESS())
        const cost = await bahndrToken.methods.weiPerToken().call().catch((err: any) => {
            console.log(`Received error: ${err}`)
        })
        console.log(`Current cost per ticket is ${cost} wei per token`)
        this.context.setCostPerTicket(cost)

        //load whoever is the owner of the contract
        const lotteryOwner = await bahndrToken.methods.owner().call()
        console.log(`Owner of Lottery Contract is ${lotteryOwner}`)
        this.context.setLotteryOwner(lotteryOwner)
    }

    onAuthorizeClick = async () => {
        //clear the errors
        this.clearErrors()

        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
            const provider: any = await detectEthereumProvider()
            const web3 = new Web3(provider || "http://localhost:8545")

            const accounts: string[] = await provider.request({ method: 'eth_requestAccounts' }).catch((err: EthereumProviderError<unknown>) => {
                console.log(`Received error when attempting to authorize Metamask account with code:${err.code}, message: ${err.message}`)
                this.setState({ hasMetaMaskError: true, metaMaskError: `Error when attempting to authorize Metamas: ${err.message}(${err.code})` })
                return
            })

            if (accounts) {
                const network = await web3.eth.net.getNetworkType()
                const networkID = await web3.eth.net.getId()
                console.log(`NetworkID is ${networkID}`);
                console.log(`Account returned has ${accounts.length} elements `)

                if (networkID !== GET_NETWORK_CHAIN_ID()) {
                    console.error(`User selected wallet in ${network} (Chain ID: ${networkID}) which is not supported by this app (expected: ${GET_NETWORK_DISPLAY_NAME()})`)
                    this.setState({ hasMetaMaskError: true, metaMaskError: `Please select a wallet on the ${GET_NETWORK_DISPLAY_NAME()} network` })
                    return
                }


                this.setState({ accounts: accounts })

                if (accounts &&
                    accounts.length > 0) {

                    this.setState({ canUseApp: true })
                    this.context.setSelectedAddress(accounts[0])
                    const bahndrToken = new web3.eth.Contract(CONTRACT_ABI as AbiItem[], GET_CONTRACT_ADDRESS())
                    this.context.setContract(bahndrToken)

                    const etherBalanceOfSelectedAccount =
                        web3.utils.fromWei(
                            await web3.eth.getBalance(
                                this.context.selectedAddress)
                        )

                    const balance = await bahndrToken.methods.balanceOf(this.context.selectedAddress).call()
                    const tickets = await bahndrToken.methods.ticketsForAddress(this.context.selectedAddress).call()


                    this.setState({ tokenBalance: balance })
                    this.context.setTickets(tickets)
                    this.context.setSelectedAccountEtherBalance(etherBalanceOfSelectedAccount)
                }
                else {
                    //no accounts configured in metamask, need to display error
                    this.setState({ canUseApp: false })
                }
            }
        }
        else {
            console.error(`Metamask was not detected to be installed`)
        }

    }

    async updateTicketsForSelectedAddress() {
        const contract = this.context.contract;
        const tickets = await contract.methods.ticketsForAddress(this.context.selectedAddress).call()
        this.context.setTickets(tickets)
    }


    //this method will be called whenever someone wants to purchase additional tickets
    onBuyNewLotteryTicket(numberOfTicketsToPurchase: number) {
        this.context.setFormState(FormState.PROCESSING)

        const amountOfWeiToSend = this.context.costPerTicket * numberOfTicketsToPurchase;
        const gasLimit = GASLIMIT_PER_TICKET * numberOfTicketsToPurchase;
        console.log(`Sending ${amountOfWeiToSend} wei from ${this.context.selectedAddress} with a gasLimit=${gasLimit}`)
        try {
            this.context.contract.methods.enterLottery(this.context.selectedAddress).send({
                from: this.context.selectedAddress,
                value: amountOfWeiToSend
            }).once('receipt', async (receipt: any) => {
                this.context.setFormState(FormState.RESULT)
                this.context.setDidLastPurchaseSucceed(true)
                this.context.setLastPurchaseErrorMessage(null)
                this.context.setLastPurchaseReceipt(receipt);
                await this.updateTicketsForSelectedAddress()
                console.log(`Purchase of ${numberOfTicketsToPurchase} tickets succeeded, Consumed: ${receipt.gasUsed}`)

            }).catch((err: EthereumProviderError<unknown>) => {
                console.log(`Purchase failed due to message:${err.message}(${err.code})`)
                this.context.setFormState(FormState.RESULT)
                this.context.setDidLastPurchaseSucceed(false)
                this.context.setLastPurchaseErrorMessage(`${err.message}(${err.code})`)
            })
        }
        catch (err) {
            console.log(`Purchase failed due to:${err}`)
            this.context.setFormState(FormState.RESULT)
            this.context.setDidLastPurchaseSucceed(false)
            this.context.setLastPurchaseErrorMessage(err)

        }


    }

    renderLastWinnerString() {
        if (this.context.previousLotteryWinner != null) {
            const previousWinner = this.context.previousLotteryWinner as PreviousLotteryWinner
            const prizeInETH = parseFloat(Web3.utils.fromWei(previousWinner.prize.toString()))
            const prizeInUSD = (prizeInETH * this.context.exchangeRate).toFixed(2).toString()
            const url = GET_ETHERSCAN_URL_FOR_TRANSACTION(previousWinner.transactionHash)
            const day = previousWinner.date.toLocaleDateString()
            return (
                <div>
                    <div><a href={url}>{previousWinner.address}</a></div>
                    <div>{prizeInETH} (~ ${prizeInUSD}) @ {day} </div>

                </div>
            )
        }

    }

    render() {
        const lotteryTicketProps: LotteryTicketsProps = {
            numberOfTickets: this.state.tokenBalance
        }

        const lotteryEntryFormProps: LotteryEntryFormProps = {
            onBuyNewLotteryTicket: this.onBuyNewLotteryTicket,
            accounts: this.state.accounts,
            weiPerToken: this.context.costPerTicket
        }

        return (
            <div>

                <div id="main-content" className="container">
                    <div className="row">

                        <div className="col d-flex justify-content-center">

                            <img src="./logo150.png"></img>

                            <div>
                                <h1 >Welcome to {NAME}</h1>
                                <h3> {GET_NETWORK_DISPLAY_NAME()} edition</h3>
                                <p className="text-center">An Ethereum-blockchain based lottery.</p>

                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-sm">
                            <p>The {NAME} is a lottery run completely on the {GET_NETWORK_DISPLAY_NAME()} blockchain. Each lottery ticket is a ERC-721 (NFT) token, you buy tickets by sending money to the {NAME} contract. All the money sent to the contract becomes the jackpot, with the winner drawn at random at fixed dates.</p>
                            <p>To play, you will need Metamask installed and a wallet configured on the {NETWORK} network. After that, just buy any number of tickets using your ${GET_CURRENCY_NAME()} wallet, which are issued by the {NAME} contract. At the next drawing date, a ticket is selected at random and the owner of that ticket wins the pot of {GET_CURRENCY_NAME()}!</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div>Cost per Ticket ({GET_CURRENCY_NAME()}):</div>
                            <div>{this.getCurrentCostPerTicketInETH()} (~ ${this.getCurrentCostPerTicketInUSD()})</div>
                        </div>
                        <div className="col">
                            <div>Current Prize ({GET_CURRENCY_NAME()}):</div><div>{this.getCurrentPrizeInETH()} (~ ${this.getCurrentPrizeInUSD()}) </div>
                        </div>
                        <div className="col">
                            <div>Next Drawing Date:</div>
                            <div>{this.context.nextDrawingDate}</div>
                        </div>
                        {this.context.previousLotteryWinner && (
                            <div className="col">
                                <div>Last Winner:</div>
                                <div>{this.renderLastWinnerString()}</div>
                            </div>
                        )}
                    </div>
                    {this.state.canUseApp ? (
                        <div className="row mt-4">
                            <div className="col-8">
                                <h3>Buy Tickets:</h3>
                                <LotteryEntryForm {...lotteryEntryFormProps} />
                                <LotteryManager />
                            </div>
                            <div className="col">
                                <h3>Your Tickets:</h3>
                                <LotteryTickets {...lotteryTicketProps} />
                            </div>

                        </div>
                    ) : (
                        <div className="row mt-4">
                            <h3>Ready to play?</h3>
                            <div className="col" >

                                {MetaMaskOnboarding.isMetaMaskInstalled() ? (
                                    <div>
                                        <p className="text-justify">To participate in {NAME}, you first need to connect your MetaMask account. Click below to get started.</p>
                                        <button type="button" onClick={this.onAuthorizeClick} className="btn btn-primary">Connect to MetaMask</button>
                                        {this.state.hasMetaMaskError && (
                                            <div className="alert alert-primary" role="alert">
                                                Failed to connect to Metamask: {this.state.metaMaskError}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <p className="text-justify">To participate in {NAME}, you first need to install MetaMask and setup an ${GET_CURRENCY_NAME} account. Click below to install MetaMask.</p>
                                        <button type="button" onClick={this.onInstallMetamaskClick} className="btn btn-primary">Install MetaMask</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                </div>
            </div >);
    }
}