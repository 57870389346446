import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.css';

ReactDOM.render(
    <App />
  ,
  document.getElementById("root")
);
