import React, { Component } from 'react'
import { BahndrAppContext } from './context/BahndrAppContext'
export interface LotteryTicketsProps {
    numberOfTickets: any

}

export interface LotteryTicketsState {

    numberOfTickets: any
}

export class LotteryTickets extends Component<LotteryTicketsProps, LotteryTicketsState>
{
    static contextType = BahndrAppContext


    static getDerivedStateFromProps(nextProps: LotteryTicketsProps, prevState: LotteryTicketsState): LotteryTicketsState {
        let newState = {} as LotteryTicketsState

        newState.numberOfTickets = nextProps.numberOfTickets
        return newState
    }

    renderList() {

        if (this.context.tickets &&
            this.context.tickets.length > 0) {
            const listItems = this.context.tickets.map((ticketId: string) => {
                return (
                    <li className="tickets" key={ticketId}>
                        <div className="ticketTemplate">
                            <label>
                                <span className="content">TicketId: {ticketId}</span>
                            </label>
                        </div>
                    </li>)

            })
            return listItems
           
        }
        else {
            return (<div><p>There are no tickets associated with this address.</p></div>)
        }
    }

    constructor(props: LotteryTicketsProps) {
        super(props as any)
        this.state = {
            numberOfTickets: props.numberOfTickets
        }
    }


    render() {
        return (
        <BahndrAppContext.Consumer>{ (value)=>      
            <div className="tickets" >
                <ul id="ticketsList" className="list-unstyled">

                    {this.renderList()}
                </ul>
            </div >

        }
        </BahndrAppContext.Consumer>
        )
    }
}

