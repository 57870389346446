import * as React from "react";
import { Component } from 'react'
import './App.css'
import { LotteryProps,Lottery } from './Lottery'
import { BahndrAppProvider } from './context/BahndrAppContext'

class App extends Component<{}, { }> {
  



  constructor(props: any) {
    super(props)
    this.state = {}
  }

 

  render() {
    const lotteryProps:LotteryProps ={}
    return (
      <div>
        <BahndrAppProvider>
          <Lottery {...lotteryProps}/>
        </BahndrAppProvider>
      </div>);
  }

}

export default App;