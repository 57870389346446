import { GET_CONTRACT_ADDRESS, NETWORK } from "../config"

import { PreviousLotteryWinner } from "../context/BahndrAppContext"

export const ETHERSCAN_API = require('etherscan-api').init(GET_ETHERSCAN_API_KEY(), NETWORK)
const axios = require('axios').default




export function GET_ETHERSCAN_API_KEY():string {
    if (NETWORK === "rinkeby") {
        return "487YF458VYTEZ3BYYFE7P6DZU1ZN8MKEAT"
    }
    else if (NETWORK ==="polytest") {
        return "3UERCE7RJQVCZBRDTYKVIXAW7XH7RZUETT"
    }
    else if (NETWORK ==="polygon")
    {
        return "3UERCE7RJQVCZBRDTYKVIXAW7XH7RZUETT" 
    }
    else{
        return ""
    }
}


export function GET_ETHERSCAN_URL_FOR_TRANSACTION(transactionHash: string): string {
    return `${GET_ETHERSCAN_URL()}/tx/${transactionHash}`
}

export function GET_ETHERSCAN_URL(): string {
    if (NETWORK === "rinkeby") {
        return "https://rinkeby.etherscan.io"
    }
    else if (NETWORK === "polytest")
    {
        return "https://mumbai.polygonscan.com/"
    }
    else if (NETWORK === "polygon")
    {
        return "https://polygonscan.com/"
    }
    else {
        return "https://etherscan.io"
    }
}

export function GET_ETHERSCAN_API_URL(): string {
    if (NETWORK === "rinkeby") {
        return "https://api-rinkeby.etherscan.io"
    }
    else if (NETWORK ==="polytest")
    {
        return "https://api-testnet.polygonscan.com"
    }
    else if (NETWORK === "polygon")
    {
        return "https://api.polygonscan.com/"
    }
    else {
        return "https://api-etherscan.io"
    }
}

async function GetTransactionsForAddress(address: string): Promise<any[]> {
    try {
        const transactionList = await axios.get(`${GET_ETHERSCAN_API_URL()}/api?module=account&action=txlist&sort=desc&address=${GET_CONTRACT_ADDRESS()}&apiKey=${GET_ETHERSCAN_API_KEY()}`)
        console.log(`Number of Transactions: ${transactionList.data.result.length}`)
        return transactionList.data.result
    }
    catch (error: any) {
        console.log(`Received error when attempting get transactions for address: ${error}`)
        return []
    }

}

export async function GetAddressOfLastLotteryWinner(contract_address: string): Promise<PreviousLotteryWinner | null> {
    const transactions: any[] = await GetTransactionsForAddress(contract_address)
    let retVal: PreviousLotteryWinner | null = null
    for (let value of transactions) {
        if (value.from.toLowerCase() === contract_address.toLowerCase() &&
            value.type === "call") {
            retVal = { address: value.to, date: new Date(value.timeStamp * 1000), prize: parseFloat(value.value), transactionHash: value.hash }
        }
    }
    return retVal


}

export async function GetETHtoUSDExchangeRate(): Promise<number> {
    const result = await axios.get(`${GET_ETHERSCAN_API_URL()}/api?module=stats&apiKey=${GET_ETHERSCAN_API_KEY()}&action=ethprice`)
    return parseFloat(result.data.result.ethusd)

}