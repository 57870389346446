import React, { Component } from 'react'
import { BahndrAppContext } from './context/BahndrAppContext'
import { FormState } from './context/BahndrAppContext'
import { EthereumProviderError } from 'eth-rpc-errors'
import { PreviousLotteryWinner } from "./context/BahndrAppContext"
import Web3 from 'web3'
export interface LotteryManagerProps {

}

interface LotteryManagerState {
    formState: FormState,
    didLastCompletionSucceed: boolean | null,
    errorMessage: string | null,
    lastWinningAddress: string,
    lastWinningPrize: number,
    lastWinningTicketId: string
}

export class LotteryManager extends Component<LotteryManagerProps, LotteryManagerState> {
    static contextType = BahndrAppContext
    constructor(props: LotteryManagerProps) {
        super(props)
        this.state = {
            formState: FormState.READY,
            didLastCompletionSucceed: null,
            errorMessage: null,
            lastWinningAddress: '',
            lastWinningPrize: 0,
            lastWinningTicketId: ''
        }
    }
    getRandomInt(min: number, max: number): number {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    }

    selectRandomTicketID = async (): Promise<number> => {
        const totalSupply = await this.context.contract.methods.totalSupply().call()
        const randomIndex = this.getRandomInt(0, totalSupply)

        //now lets get the ticket ID corresponding to this index
        const ticketId = await this.context.contract.methods.tokenByIndex(randomIndex).call()
        console.log(`Selected TicketID: ${ticketId} as Index: ${randomIndex} as lottery winner`)
        return parseInt(ticketId)

    }

    isLotteryOwner = (): boolean => {
        return (this.context.selectedAddress.toString().toLowerCase() === this.context.lotteryOwner.toString().toLowerCase())
    }

    getLastPrizeInETH = (): string => {
        return Web3.utils.fromWei(this.state.lastWinningPrize.toString())
    }

    onDrawLotteryClicked = async (e: any) => {
        e.preventDefault()
        this.setState({ formState: FormState.PROCESSING })

        //first need to choose a ticket winner randomly
        const winningTicketID = await this.selectRandomTicketID()

        let options = {
            filter:
            {
                value: [],
            },
            fromBlock: "latest"
        }
        this.context.contract.events.LotteryWon(options)
            .once('data', (event: any) => {
                const winningAddress = event.returnValues.owner
                const amount = parseFloat(event.returnValues.amount)
                const ticketId = event.returnValues.number
                const transactionHash = event.transactionHash
                console.log(`Received LotteryWon event, Transaction Hash: ${transactionHash}, Winning Address:${winningAddress}, Amount:${amount},TicketID:${ticketId}`)

                const previousWinner:PreviousLotteryWinner = {
                    address:winningAddress,
                    date:new Date(),
                    prize:amount,
                    transactionHash: transactionHash
                }

                this.context.setPreviousLotteryWinner(previousWinner)
                this.setState({ lastWinningAddress: winningAddress })
                this.setState({ lastWinningPrize: amount })
                this.setState({ lastWinningTicketId: ticketId })
                this.setState({ didLastCompletionSucceed: true })
                this.setState({ errorMessage: '' })
            })


        this.context.contract.methods.completeLottery(winningTicketID).send({
            from: this.context.selectedAddress
        }).once('receipt', async (receipt: any) => {
            console.log(`Completion of lottery suceeded`)
            this.setState({ formState: FormState.RESULT })
            this.setState({ didLastCompletionSucceed: true })
            this.setState({ errorMessage: null })

        }).catch((err: EthereumProviderError<unknown>) => {
            console.error(`Completion of lottery failed due to: ${err.message}(${err.code})`)
            this.setState({ formState: FormState.RESULT })
            this.setState({ didLastCompletionSucceed: false })
            this.setState({ errorMessage: `${err.message}(${err.code})` })

        })


    }

    renderResult() {
        if (this.state.formState === FormState.RESULT) {
            if (this.state.didLastCompletionSucceed) {
                return (
                    <div className="alert alert-success" role="alert">
                        Success! Completed Lottery with TicketID:{this.state.lastWinningTicketId} being the winner!
                    </div>
                )
            }
            else {
                return (
                    <div className="alert alert-primary" role="alert">
                        Failure attempting to complete lottery: {this.state.errorMessage}
                    </div>
                )
            }
        }
        else {
            return <div />
        }
    }

    render() {
        return (
            <div>
                {this.isLotteryOwner() && (
                    <div className="mt-4">
                        <h3>Manage Lottery</h3>
                        <div className="row mt-2">
                            <div className="col">
                                <div>Winning Address:</div>
                                <div>Winning TicketID:</div>
                                <div>Prize:</div>
                            </div>
                            <div className="col">
                                <div>{this.state.lastWinningAddress}</div>
                                <div>{this.state.lastWinningTicketId}</div>
                                <div>{this.getLastPrizeInETH()}</div>
                            </div>
                        </div>
                        <div className="row">
                            {this.renderResult()}
                        </div>
                        <div className="row mt-4">

                            <div className="col">
                                {this.state.formState !== FormState.PROCESSING ? (
                                    <button type="button" onClick={this.onDrawLotteryClicked} className="btn btn-primary">Process Lottery</button>
                                ) : (
                                    <button type="button" className="btn btn-primary" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span className="sr-only"> Finalizing Lottery...</span>
                                    </button>
                                )}
                            </div>
                        </div>

                    </div>
                )}

            </div>

        )
    }
}