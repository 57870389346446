import React, { Component, createContext } from 'react'

export enum FormState{
    READY=1,
    PROCESSING=2,
    RESULT=3
}

export interface PreviousLotteryWinner {
    address:string,
    date:Date,
    prize:number,
    transactionHash:string

}

export interface IBahndrAppContext {
    selectedAddress: string
    setSelectedAddress: (value: string) => void
    tickets:any[]
    setTickets:(ticketsList:any[])=> void
    contract:any
    setContract:(newContract:any)=>void
    formState:FormState
    setFormState:(newState:FormState)=>void
    didLastPurchaseSucceed:boolean|null
    setDidLastPurchaseSucceed:(result:boolean)=>void
    lastPurchaseErrorMessage:string|null
    setLastPurchaseErrorMessage:(errorMessage:string)=>void
    prize:string
    setPrize:(p:string)=>void
    nextDrawingDate:string
    setDrawingDate:(date:string)=>void
    costPerTicket:number
    setCostPerTicket:(cost:number)=>void
    selectedAccountEtherBalance:number
    setSelectedAccountEtherBalance:(balance:number)=>void
    lastPurchaseReceipt:any|null
    setLastPurchaseReceipt:(receipt:any)=>void
    previousLotteryWinner:PreviousLotteryWinner|null
    setPreviousLotteryWinner:(previous:PreviousLotteryWinner)=>void
    exchangeRate:number
    setExchangeRate:(exchange:number)=>void
    lotteryOwner:string
    setLotteryOwner:(owner:string)=>void
    
}

const defaultState = {
    selectedAddress: 'za',
    tickets:[],
    contract:'',
    didLastPurchaseSucceed:null,
    lastPurchaseErrorMessage:null,
    formState:FormState.READY,
    prize:'',
    nextDrawingDate:'',
    costPerTicket:0,
    selectedAccountEtherBalance:0,
    lastPurchaseReceipt:null,
    previousLotteryWinner:null,
    exchangeRate:0,
    lotteryOwner:'',
    setSelectedAddress: (value: string) => {

    },
    setTickets:(ticketsList:any[]) =>
    {
       
    },
    setContract:(newContract:any)=>{},
    setFormState:(newState:FormState)=>{},
    setDidLastPurchaseSucceed:(result:boolean)=>{},
    setLastPurchaseErrorMessage:(errorMessage:string)=>{},
    setPrize:(p:string)=>{},
    setDrawingDate:(date:string)=>{},
    setCostPerTicket:(cost:number)=>{},
    setSelectedAccountEtherBalance:(balance:number)=>{},
    setLastPurchaseReceipt:(receipt:any)=>{},
    setPreviousLotteryWinner:(previous:PreviousLotteryWinner)=>{},
    setExchangeRate:(exchange:number)=>{},
    setLotteryOwner:(owner:string)=>{}
}

export const BahndrAppContext = createContext<IBahndrAppContext>(defaultState)
export class BahndrAppProvider extends Component<{}, IBahndrAppContext> {
    state = defaultState

    render() {
        return (
            <BahndrAppContext.Provider
                value={{
                    selectedAddress: this.state.selectedAddress,
                    contract:this.state.contract,
                    formState:this.state.formState,
                    didLastPurchaseSucceed:this.state.didLastPurchaseSucceed,
                    lastPurchaseErrorMessage:this.state.lastPurchaseErrorMessage,
                    prize:this.state.prize,
                    nextDrawingDate:this.state.nextDrawingDate,
                    costPerTicket:this.state.costPerTicket,
                    selectedAccountEtherBalance:this.state.selectedAccountEtherBalance,
                    lastPurchaseReceipt:this.state.lastPurchaseReceipt,
                    previousLotteryWinner:this.state.previousLotteryWinner,
                    exchangeRate:this.state.exchangeRate,
                    lotteryOwner:this.state.lotteryOwner,
                    setSelectedAddress: (value: string) => {
                        this.setState({ selectedAddress: value })
                    },
                    tickets:this.state.tickets,
                    setTickets:(ticketsList:any[]) => {
                        this.setState({tickets:ticketsList})
                    },
                    setContract:(newContract:any)=>{
                        this.setState({contract:newContract})
                    },
                    setFormState:(newFormState:FormState)=>{
                        this.setState({formState:newFormState})
                    },
                    setDidLastPurchaseSucceed:(result:boolean)=>{
                        this.setState({didLastPurchaseSucceed:result})
                    },
                    setLastPurchaseErrorMessage:(errorMessage:string)=>{
                        this.setState({lastPurchaseErrorMessage:errorMessage})
                    },
                    setPrize:(p:string) => {
                        this.setState({prize:p})
                    },
                    setDrawingDate:(date:string)=>{
                        this.setState({nextDrawingDate:date})
                    },
                    setCostPerTicket:(cost:number)=>{
                        this.setState({costPerTicket:cost})
                    },
                    setSelectedAccountEtherBalance:(balance:number)=>{
                        this.setState({selectedAccountEtherBalance:balance})
                    },
                    setLastPurchaseReceipt:(receipt:any)=>{
                        this.setState({lastPurchaseReceipt:receipt})
                    },
                    setPreviousLotteryWinner:(previous:PreviousLotteryWinner)=>{
                        this.setState({previousLotteryWinner:previous})
                    },
                    setExchangeRate:(exchange:number)=>{
                        this.setState({exchangeRate:exchange})
                    },
                    setLotteryOwner:(owner:string)=>{
                        this.setState({lotteryOwner:owner})
                    }
                }}>
                {this.props.children}
            </BahndrAppContext.Provider>
        )
    }
}
// export const BahndrAppProvider2: FC = ({ children }) => {
//     const [selectedAddress, setSelectedAddress] = useState(defaultState.selectedAddress)


//     return (
//         <BahndrAppContext.Provider value={{ selectedAddress, setSelectedAddress }}>
//             {children}
//         </BahndrAppContext.Provider>
//     )
// }
