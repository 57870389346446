import React, { Component, createRef } from 'react'
import Web3 from 'web3'
import { BahndrAppContext, FormState } from './context/BahndrAppContext'
import { GET_ETHERSCAN_URL_FOR_TRANSACTION } from './external/Etherscan'
import detectEthereumProvider from '@metamask/detect-provider'
import {CalculateGasForPurchasing} from './config'

export interface LotteryEntryFormProps {


    onBuyNewLotteryTicket: (numberToPurchase: number) => void,
    accounts: string[],
    weiPerToken: number
}

export interface LotteryEntryFormState {
    numberOfTicketsToPurchase: any
    accounts: string[],
    subtotal: number,
    estimatedGas: number
}


export class LotteryEntryForm extends Component<LotteryEntryFormProps, LotteryEntryFormState>
{
    static contextType = BahndrAppContext
    private inputBoxRef = createRef<HTMLInputElement>()
    private addressSelectorRef = createRef<HTMLSelectElement>()
    private lblTotalPurchasePriceRef = createRef<HTMLLabelElement>()


    componentDidMount() {
        if (this.context.selectedAccount) {
            this.updateBalanceAndTicketsOfSelectedAccount(this.context.selectedAccount)
        }
    }


    constructor(props: LotteryEntryFormProps) {
        super(props)
        this.state = {
            numberOfTicketsToPurchase: 0,
            accounts: props.accounts,
            subtotal: 0,
            estimatedGas: 0
        }



    }

    static getDerivedStateFromProps(nextProps: LotteryEntryFormProps, prevState: LotteryEntryFormState): LotteryEntryFormState {
        let newState = {} as LotteryEntryFormState

        if (nextProps.accounts) {
            newState.accounts = nextProps.accounts
        }


        return newState
    }

    getSubtotalInETH = () :string =>
    {
        return Web3.utils.fromWei(this.state.subtotal.toString())
    }

    getSubtotalInUSD = (): string => {
        const costInETH = parseFloat(this.getSubtotalInETH());
        return (costInETH*this.context.exchangeRate).toFixed(2).toString()
    }


    getGasInETH = () : string =>
    {
        return Web3.utils.fromWei(this.state.estimatedGas.toString())
    }

    getGasInUSD = (): string => {
        const costInETH = parseFloat(this.getGasInETH());
        return (costInETH*this.context.exchangeRate).toFixed(2).toString()
    }

    getEstimatedTotalInETH = () : string =>
    {
        return Web3.utils.fromWei((this.state.estimatedGas + this.state.subtotal).toString())
    }

    getEstimatedTotalInUSD = (): string => {
        const gasCostInUSD = parseFloat(this.getGasInUSD())
        const subtotalInUSD = parseFloat(this.getSubtotalInUSD())
        return (gasCostInUSD+subtotalInUSD).toFixed(2).toString()
    }

    canPurchaseAmountEntered = () :boolean =>{
        return (this.context.selectedAccountEtherBalance <= this.getSubtotalInETH())
    }
    calculateEstimatedGas = async () => {
        const provider: any = await detectEthereumProvider()
        const web3 = new Web3(provider || "http://localhost:8545")
        const gasPrice = parseFloat(await web3.eth.getGasPrice())
        const estimatedGas = CalculateGasForPurchasing(this.state.numberOfTicketsToPurchase)
        const totalGasRequired = Math.floor(gasPrice*estimatedGas)
        this.setState   ({estimatedGas:totalGasRequired})
        console.log(`Estimated Gas for Transaction: ${estimatedGas}, Current Gas Price: ${gasPrice}, Total Cost: ${totalGasRequired}`)
    }


    onNumberOfTicketsInputChange = (e: any) => {
        this.context.setFormState(FormState.READY)

        if (this.inputBoxRef.current!.value &&
            this.inputBoxRef.current!.value !== '') {
            const num: number = parseInt(this.inputBoxRef.current!.value)
            this.setState({ numberOfTicketsToPurchase: num })
            //now we calculate the total price
            const subtotal: number = (num * this.props.weiPerToken)
            this.setState({ subtotal: subtotal })

           // this.lblTotalPurchasePriceRef.current!.textContent = Web3.utils.fromWei(subtotal.toString())
            this.calculateEstimatedGas()
        }


    }

    onSubmit = (e: any) => {
        e.preventDefault()
        const numberOfTicketsToPurchase: number = parseInt(this.inputBoxRef.current!.value)

        this.props.onBuyNewLotteryTicket(numberOfTicketsToPurchase)

    }

    async updateBalanceAndTicketsOfSelectedAccount(selectedAddress: string) {
        //now we need to get the ether balance ofr this address
        const web3 = new Web3(Web3.givenProvider || "http://localhost:8545")
        this.context.setSelectedAccountEtherBalance(parseInt(web3.utils.fromWei(await web3.eth.getBalance(selectedAddress))))
        //lets also get back a list of the tickets
        const tickets = await this.context.contract.methods.ticketsForAddress(this.context.selectedAddress).call()
        this.context.setTickets(tickets)
    }


    onSelectChange = async () => {
        this.context.setFormState(FormState.READY)
        const selectedAddress: string = this.addressSelectorRef.current!.value
        this.context.setSelectedAddress(selectedAddress)
        await this.updateBalanceAndTicketsOfSelectedAccount(selectedAddress)
    }

    renderResult() {
        if (this.context.formState === FormState.RESULT) {
            if (this.context.didLastPurchaseSucceed &&
                this.context.lastPurchaseReceipt &&
                this.context.lastPurchaseReceipt.transactionHash) {
                return (
                    <div className="alert alert-success" role="alert">
                        Success! Your Transaction Hash is <a href={GET_ETHERSCAN_URL_FOR_TRANSACTION(this.context.lastPurchaseReceipt.transactionHash)}>{this.context.lastPurchaseReceipt.transactionHash}</a>
                    </div>)
            }
            else {
                return (
                    <div className="alert alert-primary" role="alert">
                        Failure attempting to purchase: {this.context.lastPurchaseErrorMessage}
                    </div>
                )
            }
        }
        else {
            return ''
        }
    }
    render() {
        
        return (
            <div id="lottery-entry-form" className="row">
                <form onSubmit={this.onSubmit}>
                    <fieldset disabled={this.context.formState === FormState.PROCESSING}>
                        <div className="row mt-2">
                            <div className="col-8">
                                <div className="form-group">
                                    <label htmlFor="accountAddress">Select Address:</label>
                                    <div>
                                        <select id="accountAddress" ref={this.addressSelectorRef} className="form-control" onChange={this.onSelectChange}>
                                            {
                                                this.state.accounts.map((account) => {
                                                    return <option key={account}>{account}</option>
                                                })
                                            }

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <label>Ether Balance:</label>
                                <div><label>{this.context.selectedAccountEtherBalance}</label></div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-8">
                                <div className="form-group">
                                    <label htmlFor="numberOfTickets">Number of Tickets:</label>
                                    <input id="numberOfTickets" ref={this.inputBoxRef} type="number" className="form-control" onInput={this.onNumberOfTicketsInputChange} placeholder="# of Tickets to Buy..." required />
                                </div>
                            </div>


                        </div>
                        <div className="row mt-4">
                            <h3>Purchase Summary:</h3>
                            <div className="col">
                                <div>Sub-Total:</div>
                                <div>Estimated Gas:</div>
                                <div>Estimated Total:</div>
                            </div>
                            <div className="col">
                                <div>{this.getSubtotalInETH()} (~${this.getSubtotalInUSD()})</div>
                                <div>{this.getGasInETH()} (~${this.getGasInUSD()})</div>
                                <div>{this.getEstimatedTotalInETH()} (~${this.getEstimatedTotalInUSD()})</div>
                            </div>
                        </div>
                        <div className="row">
                            {this.renderResult()}
                        </div>
                        <div className="row mt-4">
                            {this.context.formState === FormState.PROCESSING && (
                                <button className="btn btn-primary" type="submit" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="sr-only"> Purchasing...</span>
                                </button>
                            )}
                            {this.context.formState !== FormState.PROCESSING && (
                                <button className="btn btn-primary" type="submit" disabled={this.canPurchaseAmountEntered()}>Purchase</button>
                            )}

                        </div>
                    </fieldset>
                </form>
            </div>
        )
    }
}